.sihs-main {
    overflow: hidden;
    padding: 1rem 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
}

.sihs-title {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 16px;
}

.sihs-question {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 1px solid #EAECF0;
    margin-top: 10px;
}

.sihs-question-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 6px;
}

.sihs-question-title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
}

.sihs-answer {
    color: #667085;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
}

.sihs-main-calculator {
    overflow: hidden;
    padding: 1rem 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* align-items: center; */
    margin-top: 4rem;
}

.sihs-title-calculator {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    /* text-align: center; */
    margin-bottom: 16px;
}

.sihs-question-calculator {
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 1px solid #EAECF0;
    margin-top: 10px;
}

@media screen and (min-width: 780px) {
    .sihs-question-header {
        gap: 8rem
    }

    .sihs-answer {
        width: 560px
    }
}

@media screen and (min-width: 1080px) {
    .sihs-question-header {
        gap: 12rem;
    }

    .sihs-answer {
        width: 626px;
    }
}

@media screen and (min-width: 1280px) {

    .sihs-title {
        font-size: 52px;
        font-weight: 800;
        line-height: 44px;
        letter-spacing: -0.02em;
        margin-bottom: 32px;
    }
    .sihs-question-header {
        gap: 18rem
    }

    .sihs-question {
        margin-top: 2rem;
    }

    .sihs-question-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
    }

    .sihs-answer {
        width: 775px;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
    }
}


