#fhs-content {
    font-family: Poppins;
    font-size: 52px;
    font-weight: 800;
    line-height: 71px;
    letter-spacing: -0.02em;
    text-align: center;
    color: rgba(24, 24, 27, 1);
    margin: 10rem 5rem 3rem 5rem;
}

.fhs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
}


.fhs-card-main {
    width: 33.33%;
    height: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 4rem 1.5rem 4rem;
}

.fhs-card-subtitle {
    font-family: 'Poppins';
    font-size: 21px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    color: #18181B;
    margin-top: 2.5rem;
}

.fhs-card-description {
    font-family: 'Poppins';
    text-align: center;
    margin-top: 1.2rem;   
}

.fhs-card-description h2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0px;
}

@media screen and (max-width: 480px) {

    #fhs-content {
        margin: 5rem .5rem 2rem .5rem;
        font-size: 20px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0em;
        margin-left: 4px;
        overflow: hidden;
    }

    .fhs-main {
        margin: 0 .5rem !important;
        display: flex;
        align-items: center;
        padding: 0;
    }

    .fhs-card-main{
        padding: 1rem;
    }

    .fhs-container {
        justify-content: flex-start;
    }

    .fhs-card-main {
        width: 49%;
        /* height: 280px !important; */
    }

    .fhs-card-subtitle {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        margin-top: 1rem;
    }

    .fhs-card-description {
        margin: 0;
        margin-top: 8px;
        width: 160px;
        height: 165px;
        padding: 0;
    }

    .fhs-card-description h2 {
        width: 100%;
        height: 100%;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
    }
}

@media screen and (max-width:1280px){
    .fhs-card-main {
        height: 350px;
    }
}

@media screen and (max-width:1080px){

    #fhs-content {
        margin: 10rem 3rem 3rem 3rem
    }

    .fhs-card-main {
        padding: 4px;
        padding-top: 16px;
        height: 320px;
    }
}

@media screen and (max-width: 780px){


    #fhs-content {
        margin: 5rem 0;
    }
    .fhs-main {
        margin: 0 3rem;
    }

    .fhs-card-main {
        height: 400px;
    }


}






