.sip_calculator_heading {
  color: #000 !important;
  font-family: Poppins;
  font-size: 52px;
  font-style: normal;
  font-weight: 800 !important;
  line-height: 136.5%;
  /* 70.98px */
}

.SIP_calculator p {
  padding: 20px 0;
  font-weight: 400;
  line-height: 30px !important;
  /* font-size: 24px; */
  color: #58585A;
  text-align: center;
}

.container {
  width: 100%;
  padding: 20px;
  display: flex;
  font-size: 16px;
  text-transform: capitalize;
  justify-content: space-around;
  background-color: rgba(0, 235, 180, 0.1);
}

.container_left {
  width: 45%;
  padding: 0 20px;
  /* overflow: hidden; */
}

.button_section {
  width: 100%;
  margin: 20px 15px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.toggleButton {
  display: flex;
  justify-content: space-between;
}

button {
  display: flex;
  border: none;
  border-radius: 20px !important;
  padding: 15px 35px 15px 35px;
  width: 200px;
  font-weight: bold;
  background-color: white;
  border: 1px solid rgba(65, 95, 230, 1);
  color: rgba(65, 95, 230, 1);
  cursor: pointer;
  justify-content: center;
  margin: 10px 0px 20px 5px;
  align-items: center;
}

.clicked {
  color: white;
  background-color: rgba(65, 95, 230, 1);
}

;

.default {
  width: 45%;
  background-color: white;
  border: 1px solid rgba(65, 95, 230, 1);
  color: rgba(65, 95, 230, 1);
  /* justify-content: space-between; */
}

.slider_component {
  width: 100%;
}


.value_container {
  display: flex;
  margin: 25px auto;
  width: 90%;
  flex-direction: column;
  flex-wrap: wrap;
}

/* .range:first-child{
  margin: 30px 0 10px 0 !important;
} */


.value_container2 {
  display: flex;
  justify-content: space-between;
}

.value_container2 .monthly {
  font-weight: 400;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.numbers {
  display: flex;
  align-items: center;
  font-size: 24px;
  padding: 0;
  border-radius: 40px;
  background-color: white !important;
  border: 1px solid rgb(229, 229, 229);
}

.value_container2 .numbers input {
  color: rgba(65, 95, 230, 1);
  padding: 10px 10px;
  border-radius: 40px;
  text-align: center;
  outline: none;
  border: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: red;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}

.duration {
  background-color: rgb(255, 255, 255);
  display: flex;
  padding: 5px 0;
  width: 140px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  font-size: 24px;
}

.duration input[type="number"] {
  border: none;
  outline: none;
  -webkit-appearance: none;
  /* Remove default styles in WebKit browsers */
  -moz-appearance: textfield;
  /* Remove default styles in Firefox */
  position: relative;
  max-width: 25px !important;
  margin-right: 15px;

}

.duration input {
  color: rgba(65, 95, 230, 1);
  outline: none;
  border: none;
  width: 30px;
  font-size: 24px;
}

.year-symbol {
  font-size: 24px;
  text-align: center;
  color: rgba(65, 95, 230, 1);
}

.currency_symbol {
  text-align: center;
  width: 50px;
  color: rgba(65, 95, 230, 1);
}

.start_sip {
  margin: auto;
  width: 80%;
  text-align: center;
  padding: 20px 40px;
  border-radius: 10px;
  background-color: rgba(65, 95, 230, 1);
  color: white;
  text-decoration: none;
}

.start_sip a {
  color: white;
  text-decoration: none;
}

.range {
  margin: 20px 20px 30px 10px;
}

.range input[type="range"] {
  background-color: #9bdb34;
  border-radius: 10px;
}

.range input {
  color: olive;
  width: 100%;
}

.range_indicator {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: -42px 0px;
}

.range_indicator p {
  margin-left: -10px -10px;
}

.container_right {
  width: 53%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container_right_big {
  background-color: white;
  height: 65%;
  margin-bottom: 25px;
}

.container_right_small {
  display: flex;
  height: 30%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.container_right_small h4 {
  margin: 0;
  text-align: right;
}

.container_right_small h4+h4 {
  margin-left: 20px;
}

.container_right_small div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.invested_value,
.estimated_returns,
.total_value {
  display: flex;
  justify-content: space-around;
  width: 80%;
  /* padding: 8px; */
  /* Add padding for spacing */
  /* margin: 4px 0; */
  /* Add margin for vertical spacing */
}

.total_value .invested_value .estimated_returns p {
  margin: 10px;
  padding: 10px 0 !important;
  font-weight: 400 !important;
  line-height: 10px !important;
  font-size: 20px;
  color: #58585A;
}

h4 {
  margin: 0;
  /* Remove default margin for h4 elements */
}


@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .container {
    /* margin: 1rem; */
    /* width: 100%;
    height: auto; */
    margin: auto;
    padding: 0%;
    flex-wrap: wrap;
  }
  .dropdown-content a:focus + .sub-dropdown {
    display: block;
  }
}

@media only screen and (min-width: 770px) and (max-width: 1024px) {

  .calculator_container_right_big,
  .calculator_container_right_small {
    margin: 1rem;
    padding: auto;
  }
  .dropdown-content a:focus + .sub-dropdown {
    display: block;
  }
}

@media (max-width: 576px) {
  .container {
    margin: auto;
    width: 100%;
    flex-wrap: wrap;
  }

  .container_right,
  .container_left {
    width: 90%;
    margin: auto !important;
  }

  .value_container2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
}

/* chart stye */
.chart_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
  align-items: center
}



.chart_label h3 {
  display: flex;
  flex-direction: column;
}

.chart_label {
  display: flex;
  align-items: center;
  margin: 10px;
}

.chart_label h3::before {
  content: "●";
  /* Use the dot character as content */
  margin: 2px;
  /*Adjust the space between the dot and text
    font-size: 30px; /* Adjust the size of the dot */
}

.chart_label h3:nth-child(1)::before {
  color: blue;
  /* Color for the dot before "Invested" */
}

.chart_label h3:nth-child(2)::before {
  color: green;
  /* Color for the dot before "Gain" */
}


.input-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.currency-symbol {
  margin-right: -20px;
  font-size: 1.2rem;
}

input[type="number"] {
  border: none;
  border-bottom: 1px solid #000;
  padding: 0;
  margin: 0;
  width: 100px;
  font-size: 1.2rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .container_left,
  .container_right {
    width: 100%;
  }

  .button_section {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .default,
  .clicked {
    width: 48%;
  }

  .start_sip {
    margin-bottom: 1rem;
    color: white;
    text-decoration: none;
  }

  .sip_calculator_heading {
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .toggleButton {
    width: 100%;
  }

}