.hs-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hs-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 3px
}

.hs-container h1 {
    font-family: Poppins;
    font-size: 60px;
    font-weight: 800;
    line-height: 82px;
    letter-spacing: -0.02em;
}

/* #mutualfund::before {
    content: "Investment";
    color: #000;
}

#mutualfund::after {
    content: "Mutual Funds";
    color: #00EBB4;   
} */
#mutualfund {
    margin-right: 1rem;
    color: #00EBB4; 
    /* visibility: hidden; */

}

#mutualfund:last-word {
    color: #000;
}

.secondcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 10rem;
}

.secondcontainer p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    color: #475569;
}

.buttonimgcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.rightbuttondiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 156px;
    height: 49px;
    gap: 16px;
    border: none;
    background-color: #415FE6;
    border-radius: 5px;
    margin-top: 2.5rem;
}

.rightbuttondiv a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    padding: 1rem 1.2rem;
}

.rightbuttondiv a:hover {
    text-decoration: none;
    color: #fff;
}

.rightarrowdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
    width: 18px;
    height: 18px;
}

.rightarrowdiv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.logoimgdiv {
    width: 840px;
    height: 614px;
}

.logoimgdiv img:hover {
    cursor: pointer;
}

@media screen and (max-width: 1280px) {
    .secondcontainer {
        margin: 0 7rem;
    }

    .logoimgdiv {
        width: 750px;
        height: 500px;
    }
}

@media screen and (max-width: 1080px) {



    .hs-container h1 {
        font-size: 40px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0em;
        margin-top: 2rem;
    }

    .secondcontainer {
        margin: 0 5rem;
        margin-top: 1rem;
    }

    .logoimgdiv {
        width: 650px;
        height: 400px;
    }
}

@media screen and (max-width: 768px) {

    .hs-container h1 {
        font-size: 30px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0em;
    }

    .secondcontainer p {
        font-size: 16px;
        margin-top: 1rem;    
    }

    .secondcontainer {
        margin: 0 3rem;
    }

    .logoimgdiv {
        width: 500px;
        height: 300px;
    }
}
    


@media screen and (max-width: 480px) {
    .hs-main {
        overflow: hidden;
    }

    .hs-container{
        margin-top: .8rem;
    }
    
    .hs-container h1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0em;
    }

    .secondcontainer {
        margin: 0 2rem;
    }

    .buttonimgcontainer {
        flex-direction: column-reverse;
    }

    .logoimgdiv {
        width: 324px;
        height: 237px;
    }
}
    
