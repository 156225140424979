.indexedStep {
    color: #9F9F9F;
    width: 40px;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.indexedStep.accomplished {
    background-color: #00EBB4;
    color: #ffffff;
    font-weight: 700;
    border-style: none;
}

.indexedStep span {
    position: absolute;
    top: 40px;
    left: 50%;
    color: #000000;
    width: 150px;
    font-size: 14px;
    font-weight: 500;
    transform: translateX(-50%);
    text-align: center;
}

.RSPBprogressBar {
    height: 3px;
    width: 80%;
    line-height: 2;
    border-radius: 10px;
    position: relative;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    margin: 40px auto;
}

.RSPBprogressBar .RSPBstep {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    position: absolute;
    transform: translateX(-50%);
    transition-property: all;
    transition-timing-function: ease;
}

.RSPBprogressBar .RSPBprogressBarText {
    color: #ffffff;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.RSPBprogressBar .RSPBprogression {
    position: absolute;
    transition: width 0.3s ease;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    background: #00EBB4;
    z-index: -1;
}

@media screen and (max-width: 480px) {
    .RSPBprogressBar {
        width: 70%;
    }

    .indexedStep span {
        display: none;
    }
}