.block1 {
    margin-top: 100px;
}

.heading {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 40px;
}

.sub_heading {
    font-weight: 400 !important;
    font-size: 18px;
    line-height: 30px;
}

.list {
    font-size: 18px;
    line-height: 30px;
}

.list ul li {
    margin: 20px 0;
}

.bold_content {
    font-weight: 700;
}

.pre_heading {
    margin: 20px 0 0 0;
    font-weight: 400;
}


/* Phone View Here --->  */
@media (max-width: 600px) {}