.review-area .innerBox .form-area .RYCheckBoxCont label {
    width: 20px;
    height: 20px;
    margin: 0px;
    margin-right: 20px;
    background: #fff;
    padding: 0px;
    border: 1px solid #00EBB4;
}
.RYCheckBoxCont {
    display: flex;
}
input[type=range]::-webkit-slider-thumb:hover {
    box-shadow: #d46a6a50 0px 0px 0px 8px;
}

input[type=range]::-webkit-slider-thumb:active {
    box-shadow: #d46a6a50 0px 0px 0px 11px;
    transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 350ms cubic-
    bezier(0.4, 0, 0.2, 1) 0ms, bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}