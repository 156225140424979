.fihs-main-container {
    overflow: hidden;
    padding: 0 8px;
    margin-top: 4rem;
}

.fihs-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fihs-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;   
    text-align: center !important;
}

.fihs-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
}

.fihs-main-feature-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 20px;
}

.fihs-main-feature {
    width: 163px !important;
    height: 180px !important;
    padding: 2px;
}

.fihs-feature {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: auto;
    height: 51px;
}

.fihs-feature-icon {
    width: 24px;
    height: 24px;
    margin: 0 !important;
}

.fihs-feature-icon img{
    width: 100%;
}

.fihs-feature-title {
    width: 80%;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    padding-left: 4px;
}

.fihs-feature-description {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    padding-left: 28px;
    color: rgba(0, 0, 0, 1);
}


@media screen and (min-width: 780px){
    .fihs-main-container {
        padding: 4rem 2rem;
        margin-top: 10rem;
        position: relative;
    }

    .fihs-main {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 50%;
    }

    .fihs-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .fihs-image-container {
        position: absolute;
        width: 380px;
        height: 380px;
        right: 10px;
    }

    .fihs-title {
        justify-content: flex-start;
        text-align: start;
        font-size: 38px;
        font-weight: 800;
        line-height: 71px;
        letter-spacing: -0.02em;
        text-align: left;
        margin-bottom: 14px;
    }

    .fihs-main-feature-container {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0;
    }

    .fihs-main-feature {
        width: 100% !important;
        height: auto !important;
    }
    
    .fihs-feature {
        justify-content: flex-start;
        height: auto;
        align-items: center;
        margin-bottom: 8px;
    }

    .fihs-feature-icon {
        width: 40px;
        height: 40px
    }

    .fihs-feature-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: -0.02em;
        text-align: left;
    }

    .fihs-feature-description {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: rgb(113, 112, 112);
        padding-left: 0;
        margin-bottom: 16px;
    }
} 

@media screen and (min-width:1080px){

    .fihs-image-container {
        width: 500px;
        height: 500px;
        right: 10px;
    }
}

@media screen and (min-width:1280px){

    .fihs-main-container {
        padding: 2rem 8rem;
        margin-top: 10rem;
        position: relative;
    }

    .fihs-image-container {
        width: 600px;
        height: 600px;
        right: 40px;
    }
}
