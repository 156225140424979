.appie-loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appie-loader {
  opacity: 0;
}

.appie-loader.active {
  opacity: 100;
}

.appie-visible {
  transition: all 0.5s ease-in-out;
}

.appie-visible {
  opacity: 0;
  display: none;
}

.appie-visible.active {
  opacity: 100;
  display: block;
  transition: all 0.5s ease-in-out;
}

/* .appie-home-loader {
  transition: all 0.5s ease-in-out;
} */
.offcanvas_main_menu li ul.sub-menu {
  padding-left: 20px;
  overflow: hidden;
  transition: all linear 0.65s;
}

.appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item .title span {
  font-size: 30px !important;
  color: #fff !important;
}

.home-four-project {
  background: #eef1f6;
}

.home-four-footer {
  background: #ffffff;
}

.slick-dots li button:before {
  content: "";
}

.appie-showcase-item {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1200px;
  }
}

.appie-services-2-area.appie-services-8-area {
  overflow: hidden;
}

@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .appie-services-8-area .service-thumb {
    margin-left: 0;
    margin-right: 0;
  }
}

.save-comment input:checked+label::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f00c";
}

.appie-testimonial-area {
  overflow: hidden;
}

.testimonial-about-slider-active .testimonial-parent-item {
  display: flex !important;
  justify-content: center;
}

.testimonial-box {
  width: 770px !important;
}

.testimonial-box-about-slider-small-active .slick-center.slick-current .item .thumb img {
  width: 70px;
  display: inline-block;
}

.testimonial-box-about-slider-small-active .item .thumb img {
  display: inline-block;
  width: 40px;
}

.testimonial-box-about-slider-small-active .item .thumb {
  align-items: center;
}

.testimonial-box-about-slider-small-active .slick-center.slick-current .item {
  margin-top: -20px;
}

@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .testimonial-about-slider-active .testimonial-box {
    box-shadow: none;
    background: none;
  }
}

@media only screen and (min-width: 770px) and (max-width: 1024px) {
  .testimonial-about-slider-active .slick-arrow.next {
    right: 15px;
  }

  .testimonial-about-slider-active .slick-arrow.prev {
    left: 15px;
  }
}

.mediaYoutube{

  @media only screen and (min-width: 300px) and (max-width: 1024px) {
    flex-direction: column;
  }
}
.mediaStyle{
  width:"50%";
  @media only screen and (min-width: 300px) and (max-width: 1024px) {
    width: 100% !important; 
  }
}
/* li {
    font-size: 14px;
    margin-left: 10px;
    list-style-type: circle;
    display: inline;
 } */
 .blogContentDiv li:before {
  content: "\2022";
  color: black;
  font-size: 1.5rem;
  height:1rem;
  /* padding-right: 2em; */
  margin-right:1rem
}

/* Style for the dropdown container */
/* Style for the dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Style for the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 20px;
}

/* calculator component */
.calculator_component {
  margin-left:15%;
  margin-right:15%;
  padding: 10px;
  margin-top: 5%;
}

@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .calculator_component {
    margin: 1rem;
    height: auto;
    width: auto;
    padding: 10px;
    margin-top: 5%;
  }
}

/* Style for the dropdown container */
.dropdown {
  position: relative;
  display: block;
}

/* Style for the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 11;
  top: 40px;
}
@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .dropdown-content {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 11;
    top: 40px;
  }
}

/* Style for the dropdown links */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color on hover */
.dropdown-content a:hover {
  background-color: #ddd;
  /* width: 100%; */
}

/* Show the dropdown content on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Style for the sub-dropdown */
.sub-dropdown {
  display: none;
  position: absolute;
  top: 0;
  left: 100%; /* Position to the right of the parent dropdown */
  min-width: 160px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Show the sub-dropdown on hover of the Calculator link */
.dropdown-content a:hover + .sub-dropdown {
  display: block;
}
/* Show the sub-dropdown on hover of the Calculator link */
.dropdown-content a:focus + .sub-dropdown {
  display: block;
}

/* Ensure the dropdown remains visible while the mouse is over the dropdown or its links */
.dropdown:hover .dropdown-content,
.sub-dropdown:hover {
  display: block;
}



/* Media query for mobile devices */
@media only screen and (max-width: 1024px) {
  /* Hide the dropdown content by default on mobile */
  .dropdown-content,
  .sub-dropdown {
    display: none;
  }

  /* Show the dropdown content on click/touch */
  .dropdown.active .dropdown-content,
  .sub-dropdown.active {
    display: block;
  }

  /* Adjust styles for mobile view */
  .dropdown {
    display: block; 
  }

  .dropdown:hover,
  .dropdown.active {
    background-color: transparent;
  }

  /* Add a mobile-friendly indicator for dropdowns */
  .dropdown:after {
    content: "\25BC";
    display: inline-block;
    margin-left: 5px;
  }

  /* Remove hover styles for mobile view */
  .dropdown:hover .dropdown-content,
  .sub-dropdown:hover {
    display: none;
  }
}
