.video-main p {
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

#videofirstheading {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    margin-top: 3rem;
    margin-bottom: 1rem;
    letter-spacing: 0em;
}

#videosecondheading {
    font-size: 52px;
    font-weight: 800;
    line-height: 63px;
    margin-bottom: 2rem;
    letter-spacing: -0.02em;
    color: rgba(24, 24, 27, 1);
}

.video-container {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.videodiv {
    width: 883px;
    height: 527px;
    background-color: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.videoseconddiv {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    box-shadow: 0px 24px 154px 0px #00000040;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
    cursor: pointer
}

@media screen and (max-width: 980px) {
    .videodiv {
        width: 650px;
        height: 400px;
    }
}

@media screen and (max-width: 480px) {

    .video-main{
        overflow: hidden;
    }

    #videofirstheading {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        margin-top: 1rem;
        margin-bottom: 3px;
    }

    #videosecondheading {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
    }

    .video-container{
        margin-top: 0;
    }

    .videodiv {
        width: 340.78px;
        height: 203px;
    }

    .videoseconddiv {
        width: 46.31px;
        height: 46.31px;
    }
}