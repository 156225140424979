.shs-main {
    overflow: hidden;
    padding: 8px;
    margin-top: 3rem;
}

.shs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#sipsolutions {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.20000000298023224px;
    text-align: center;
    color: #2563EB;
}

#shs-content {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
}

.manageimagediv {
    width: 324px;
    height: 237px;
    margin-bottom: 4rem;
}

.checktextdiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 4px 20px;
}

.checktextdiv p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
}

.check-container {
    width: 24px;
    height: 24px;
}

.check-container img {
    width: 100%;
}

.buildbuttondiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 40px;
    padding: 9px, 46px, 10px, 46px;
    border-radius: 4px;
    margin-top: 1rem;
    background: #415FE6;
    color: white;
}

.buildbuttondiv a {
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
}

.buildbuttondiv a:hover {
    text-decoration: none;
    cursor: pointer;
    color: #fff;
}

@media screen and (min-width: 780px){
    .shs-main {
        position: relative;
        padding: 8px 2rem;
        height: auto;
    }

    .shs-container {
        width: 60%;
        justify-content: flex-start;
        align-items: normal;
    }

    #sipsolutions {
        text-align: left;
    }

    #shs-content {
        text-align: left;
        font-family: Poppins;
        font-size: 40px;
        font-weight: 800;
        line-height: 50px;
        letter-spacing: -2px;
        text-align: left;
    }

    .manageimagediv {
        position: absolute;
        top: 30px;
        right: 20px;
    }

    .checktextdiv {
        padding-left: 0;
    }
}

@media screen and (min-width: 1080px) {
    .shs-main {
        position: relative;
        padding: 8px 4rem;
        height: auto;
    }

    #shs-content {
        font-size: 50px;
        font-weight: 800;
        line-height: 55px;
        letter-spacing: -2px;
        text-align: left;
    }

    .manageimagediv {
        width: 400px;
        height: 270px;
        margin-bottom: 4rem;
    }

}

@media screen and (min-width: 1140px) and (max-width: 1280px) {
    .shs-main {
        position: relative;
        padding: 8px 4rem;
        height: auto;
    }

    .manageimagediv {
        position: absolute;
        top: -25px !important;
        right: 50px !important;
    }
}

@media screen and (min-width: 1280px) {
    .shs-main {
        position: relative;
        padding: 8px 6rem;
        height: auto;
    }

    #shs-content {
        font-size: 62px;
        font-weight: 800;
        line-height: 72px;
        letter-spacing: -2px;
        text-align: left;
    }

    .manageimagediv {
        top: 50px !important;
        right: 70px;
        width: 500px;
        height: 350px;
    }

    .checkdiv {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 9rem;
        margin-top: 2rem;
        margin-bottom: 4rem;
    }

    .checktextdiv p {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: left;
    }

    .check-container {
        width: 40px;
        height: 40px;
    }
    
    .buildbuttondiv {
        width: 180px;
        height: 56px;
        padding: 15.5px, 50.5px, 16.5px, 50.5px;
        border-radius: 4px;

    }

    .buildbuttondiv a {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
}



@media screen and (min-width: 1860px) {
    .manageimagediv {
        top: -35px;
        right: 70px;
        width: 500px;
        height: auto;    
    }
}