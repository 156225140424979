.thhs-main {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 6rem;
    margin-left: 6rem;
    margin-top: 10rem;
    position: relative;
}

.thhs-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 55%; /* Adjust this value as needed */
    height: 350px;
}

.thhs-image-container {
    position: absolute;
    left: 0;
    width: 506px;
    height: 356px;
    margin-right: 3rem; 
}


.thhs-image {
}

.thhs-text-container {
    width: 80%;
}

.thhs-subtitle {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    color: #64748B;
    margin-top: 4rem;
}

.thhs-title {
    font-family: 'Poppins';
    font-size: 52px;
    font-weight: 800;
    line-height: 62px;
    letter-spacing: -2px;
    text-align: left;
    margin-top: 1rem;  
}

.thhs-description {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    color: #18181B;
    margin-top: 14px; 
}

.thhs-button {
    width: 180px;
    height: 56px;
    padding: 15.5px, 61px, 16.5px, 61px;
    border-radius: 4px;
    background-color: #415FE6;
    margin-top: 1rem; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.thhs-link {
   color:#fff; 
   gap:.5rem; 
}

.thhs-link:hover {
    color:#fff;
    cursor: pointer;
}

@media screen and (max-width: 1280px){
    
    .thhs-main{
        margin-left: 4rem;
        margin-right: 4rem;
    }

    .thhs-container {
        height: 320px;
    }

    .thhs-image-container {
        width: 456px;
        height: 356px;
    }

    .thhs-subtitle{
        margin-top: 2rem;
    }
}

@media screen and (max-width: 1080px){
    
    .thhs-main{
        margin-left: 3rem;
        margin-right: 3rem;
    }

    .thhs-container {
        height: 320px;
    }

    .thhs-image-container {
        width: 400px;
        height: 316px;
    }

    .thhs-subtitle{
        margin-top: 2rem;
    }

    .thhs-title {
        font-size: 40px;
        margin-top: .5rem;
    }

    .thhs-image-container {
        margin-top: 1rem;
    }

    .thhs-description {
        margin-top: .5rem;
    }

    .thhs-button {
        width: 130px;
        height: 50px;
        padding: 12px 35px 12px 35px;
    }
}

@media screen and (max-width: 980px){
    
    .thhs-main{
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .thhs-container {
        height: 320px;
    }

    .thhs-image-container {
        width: 380px;
        height: 300px;
    }

    .thhs-subtitle{
        margin-top: 2rem;
    }

    .thhs-title {
        font-size: 35px;
        margin-top: .5rem;
    }

    .thhs-image-container {
        margin-top: 1rem;
    }

    .thhs-description {
        margin-top: .3rem;
    }

    .thhs-button {
        width: 130px;
        height: 45px;
        padding: 8px 28px 8px 28px;
    }

}

@media screen and (max-width: 880px){
    
    .thhs-main{
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .thhs-container {
        height: 320px;
    }

    .thhs-image-container {
        width: 330px;
        height: 280px;
    }

    .thhs-subtitle{
        margin-top: 1.2rem;
    }

    .thhs-title {
        font-size: 30px;
        margin-top: 4px;
    }

    .thhs-image-container {
        margin-top: 2rem;
    }

    .thhs-description {
        margin-top: .3rem;
        font-size: 14px;
    }

    .thhs-button {
        height: 40px;
        padding: 8px 28px 8px 28px;
    }

}

@media screen and (max-width: 480px) {
    .thhs-main {
        flex-direction: column;
        align-items: center;
        margin-right: 1rem;
        margin-left: 1rem;
        margin-top: 3rem;
        overflow: hidden;
    }

    .thhs-container {
        width: 100%;
        height: auto;
        align-items: center;
        text-align: center;
    }

    .thhs-image-container {
        position: static;
        width: 100%;
        height: auto;
        margin-right: 0; 
    }

    .thhs-subtitle {
        margin-top: 2rem;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
    }

    .thhs-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 1px;
    }

    .thhs-description {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
    }

    .thhs-button {
        width: 160px;
        height: 40px;
    }
}


