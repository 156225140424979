.join_desc {
    text-align: justify;
    padding: 20px;
    margin-top: -45px;
    padding-top: 50px;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    border-radius: 5px;
    min-height: 15rem;
}
.ry_instr_shadow {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 10px;
}
.btn-docsta-instructor {
    background: #7F13AB 0% 0% no-repeat padding-box !important;
    color: #f5f5f5 !important;
    display: block !important;
    width: 100%;
}
.ry_label {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 600;
}
.ry_admin_menu i {
    padding-right: 5px;
} 
.ry_admin_menu {
    padding: 7px 20px;
    display: block;
    color: #000000;
}
.ry_admin_menu:hover {
    color: #7F13AB;
}
.ry_admin_menu a {
    cursor: pointer;
}
.ry-pull-left {
    float: left;
}
.ry-pull-right {
    float: right;
}
.ry-table-header {
    background: #0C0F50 0% 0% no-repeat padding-box;
    color: #FFFFFF;
}
.instr_tot_cours {
    background: #A34CC9 0% 0% no-repeat padding-box;
    border-radius: 18px;
    text-align: center;
    color: #f5f5f5;
    padding: 30px;
    box-shadow: 0px 0px 10px #7f13ab;
}
.instr_tot_std {
    background: #49AF74 0% 0% no-repeat padding-box;
    border-radius: 18px;
    text-align: center;
    color: #f5f5f5;
    padding: 30px;
    box-shadow: 0px 0px 10px #7f13ab;
}
.instr_tot_webi {
    background: #4C9DC9 0% 0% no-repeat padding-box;
    border-radius: 18px;
    text-align: center;
    color: #f5f5f5;
    padding: 30px;
    box-shadow: 0px 0px 10px #7f13ab;
}
.instr_tot_blog {
    background: #EBAE3B  0% 0% no-repeat padding-box;
    border-radius: 18px;
    text-align: center;
    color: #f5f5f5;
    padding: 30px;
    box-shadow: 0px 0px 10px #7f13ab;
}
.ry_instr_text {
    color: #7f13ab;
}
div.PhoneInputCountry {
    padding-top: 5px;
}
input.PhoneInputInput {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    position: absolute;
    right: 0px;
    bottom: 0px;
    top: 0px;
    width: 75%;
    z-index: 888;
}
.instr_signup_head {
    background: #F4F7FF 0% 0% no-repeat padding-box;
    border: 0.5px solid #f5f5f5;
    border-radius: 20px 20px 0px 0px;
}
.instr_signup_head .col-md-4, .instr_signup_head .col-md-3 {
position:relative;
}
.instr_signup_head_step {
    border: 1px solid #4C2CD4;
    border-radius: 50%;
    margin: 20px auto;
    width: 70px;
    height: 70px;
    line-height: 65px;
    text-align: center; 
}

.instr_signup_head .active {
    background: #4C2CD4 0% 0% no-repeat padding-box;
    color:#FFFFFF;
} 
.instr_signup_head .col-md-4::before {
    content: "";
    position: absolute;
    background: #dddddd;
    top: 50px;
    right: -33%;
    width: 66%;
    height: 2px;
}
.instr_signup_head .col-md-4:last-child::before {
    display: none;
}
.instr_signup_head .col-md-3::before {
    content: "";
    position: absolute;
    background: #dddddd;
    top: 50px;
    right: -33%;
    width: 66%;
    height: 2px;
}
.instr_signup_head .col-md-3:last-child::before {
    display: none;
}

.instr_signup_head .col-md-2::before {
    content: "";
    position: absolute;
    background: #dddddd;
    top: 50px;
    right: -33%;
    width: 66%;
    height: 2px;
}
.instr_signup_head .col-md-2:last-child::before {
    display: none;
}
.step_1_img {
    max-width: 250px;
    display: block;
    margin: 0px auto;
    margin-top: 210px;
}
.step_2_img {
    max-width: 250px;
    display: block;
    margin: 0px auto;
    margin-top: 170px;
}
.step_3_img {
    max-width: 250px;
    display: block;
    margin: 0px auto;
    margin-top: 160px;
}
.instr_profile_pics {
    float: right; height: 125px; width: 125px; max-width: 50%; object-fit: cover; border-radius: 50%;
}
@media only screen and (min-width: 710px) {
    .instr_payput_type {
        display: flex;
        justify-content: space-between;
    }
}
.instr_payput_type button {
    margin-bottom: 10px;
}
.clear_both {
    clear: both;
}
.instr_webi_help {
    place-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: center;
}
.instr_webi_help .i2 {
    margin-left: 5px;
    height: 25px;
}
.instr_webi_help .i1 {
    margin-left: 5px;
    left: 1395px;
    width: 20px;
    height: 20px;
    background: #4C2CD4 0% 0% no-repeat padding-box;
    border-radius: 50%;
    color: #fff;
    line-height: 20px;
    text-align: center;
}
.h-transition {
    transition: max-height 0.15s ease-out;
}
.course_list_banner {
    max-width:100px;
}