.goal_calculator_heading {
  color: #000 !important;
  font-family: Poppins;
  font-size: 52px;
  font-style: normal;
  font-weight: 800 !important;
  line-height: 136.5%;
}

.goal_calculator p {
  padding: 20px 0;
  font-weight: 400;
  line-height: 30px !important;
  color: #58585A;
  text-align: center;
}

.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* width: 1279px; */
  width: 100%;
  height: 1136px;
  margin-left: -1rem;
  margin-bottom: 1rem;
  padding: 2rem;
  flex-shrink: 0;
  border-radius: 8px;
  background: rgba(0, 235, 180, 0.10);
  /* border: 5px solid rebeccapurple; */
}

.container_top {
  display: flex;
  flex-direction: row;
  gap: 32px;
  width: 954px;
  max-width: 100%;
  height: 180px;
  overflow: hidden;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-items: center;
}

.top_icon {
  display: flex;
  width: 192px;
  height: 74px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  /* border: 2px solid var(--Tertiary-Blue, #415FE6); */
  background: #FFF;
  cursor: pointer;
}

.top_icon_selected {
  display: flex;
  width: 192px;
  height: 74px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 2px solid var(--Tertiary-Blue, #415FE6);
  background: #FFF;
  cursor: pointer;
}
.top_icon:hover{
  background: #58585A;
  color: #FFF;
  text-decoration: none;
}

.top_icon p {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.36px;
}

.top_icon img {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

.container_bottom {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bottom_left {
  display: inline-flex;
  width: 40%;
  height: 764px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  /* margin: 1rem; */
}

.choose_goal {
  display: flex;
  width: 472px;
  max-width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.selection_input {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 79px;
}

.range_indicator {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  /* margin: -12px 0px; */
  width: 100%;
}

.range_indicator p {
  margin-left: 10px 10px;
}


.choose_goal select {
  max-width: 100%;
  display: flex;
  width: 472px;
  height: 74px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 337px;
  border-radius: 21px;
  border: 1px solid #B8B8B8;
}

.choose_time_text {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.selection_input .amount {
  max-width: 100%;
  display: flex;
  width: 474px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.amount input {
  border: none;
  outline: none;
  color: rgba(65, 95, 230, 1);;
}


.selection_input .time {
  max-width: 100%;
  display: flex;
  width: 475px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.slider_component {
  max-width: 100%;
  width: 475px;
  height: 12px;
  flex-shrink: 0;
  border-radius: 4px;
  /* background: var(--Gray-100, #F2F4F7); */
}

.selection_input .roi {
  max-width: 100%;
  display: flex;
  width: 473px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}



.start_investment button{
  max-width: 100%;
  width: 100%;
  display: flex;
  height: auto;
  width: 100%;
  /* padding: 16px 10px; */
  /* margin: 0% !important; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 21px;
  border: 1px solid var(--Tertiary-Blue, #415FE6);
  background: var(--Tertiary-Blue, #415FE6);
  color: #fff;
}

.start_investment p {
  color: #FFF;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 128.373%;
  /* 30.81px */
}


.bottom_right {
  display: flex;
  width: 55%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin-right: 2rem;
  padding: 1rem;
  gap: 1rem;
  overflow: hidden;
}

.bottom_right .graph {
  max-width: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  /* align-items: flex-start; */
  gap: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  box-shadow: 0px 2px 48px 0px rgba(91, 71, 188, 0.04);
}

.bottom_right .calculate_value {
  display: flex;
  max-width: 100%;
  width: 660px;
  height: 199px;
  padding: 40px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 2px 48px 0px rgba(0, 0, 0, 0.04);
}

.calculate_value .goal_invested_value {
  display: flex;
  max-width: 100%;
  width: 474px;
  align-items: flex-start;
  gap: 163px;
}

.calculate_value .goal_estimated_returns {
  display: flex;
  max-width: 100%;
  width: 475px;
  align-items: flex-start;
  gap: 152px
}

.calculate_value .goal_total_value {
  display: flex;
  max-width: 100%;
  width: 481px;
  align-items: flex-start;
  gap: 219px;
}

.goal_Result h6 {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  line-height: normal;
}

/* .goal_invested_value,
.goal_estimated_returns,
.goal_total_value,
.goal_sip_required,
.goal_lumpsum_required {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly !important;
  width: 100%;
  text-align: center;
  align-items: center;
   height: 100%
} */

.bottom_right .result {
  display: flex;
  max-width: 100%;
  width: 660px;
  height: 199px;
  padding: 18px 19px;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 10px 2px 48px 0px rgba(0, 0, 0, 0.04);
}

.goal_sip_required, .goal_lumpsum_required {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  gap: 144px;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
}

.goal_lumpsum_required {
  gap: 113px;
  flex-shrink: 0
}

.height_light {
  display: flex;
  height: 45px;
  width: auto;
  min-width: fit-content;
  max-width: 100%;
  padding: 3px 11px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 21px;
  background: var(--Cyan, #00EBB4);
  padding: 1rem;
}

.choose_goal_text {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.amount .symbol_input{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  max-width: 100%;
  width: 474px;
  height: 74px;
  padding: 3px 13px;
  align-items: center;
  gap: 2px;
  border-radius: 21px;
  border: 1px solid var(--Grey-3, #B8B8B8);
  background: #FFF;
}

.amount .currency_symbol{
  text-align: center;
  width: 50px;
  color: rgba(65, 95, 230, 1);
}



@media only screen and (min-width: 300px) and (max-width: 576px) {
  .layout {
    width: 100%;
    margin: 0%;
    padding: 0%;
    height: 100%;
    flex-wrap: wrap;
  }

  .container_top {
    display: none;
  }

  .container_bottom {
    flex-direction: column;
    max-width: 100% !important;
  }

  .top_icon,
  .bottom_left {
    max-width: fit-content;
    width: 100%;
    overflow: hidden;
  }

  .goal_choose_goal {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .choose_goal select {
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }

  .bottom_right {
    width: 100%;
    margin: 1rem;
    flex-direction: column-reverse;
  }

  .bottom_button {
    display: block;
  }

  .container_top {
    margin: 0;
  }

  .bottom_left {
    margin: 0;
  }

  .bottom_right {
    margin: 0;
  }

  .choose_goal {
    width: 100%;
  }

  .amount,
  .time,
  .roi {
    width: 100%;
  }

  .slider_component {
    width: 100%;
  }

  .start_investment {
    width: 100%;
  }

  .goal_calculator_heading {
    font-size: 24px;
  }

  .goal_calculator p {
    padding: 10px 0;
    line-height: 24px !important;
  }

  .container_top {
    flex-direction: column;
    align-items: center;
  }

  .container_bottom {
    flex-direction: column;
  }

  .bottom_left,
  .bottom_right {
    width: 100%;
    margin: 0;
    justify-content: space-evenly;
  }

  .bottom_right .graph,
  .bottom_right .calculate_value,
  .bottom_right .result {
    padding: 1rem;
    max-width: 100%;
    background-color: #fff;
  }

  .bottom_left .selection_input {
    gap: 20px;
    max-width: 80%;
  }

  .calculate_value,
  .goal_invested_value,
  .goal_estimated_returns,
  .goal_total_value,
  .goal_sip_required,
  .goal_lumpsum_required {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    grid-gap: 1rem !important;
    gap: 1rem !important;
    align-items: center;
  }

}