.fmf-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15rem;
    margin-bottom: 3rem;
}

.fmf-title {
    font-family: 'Poppins';
    font-size: 60px;
    font-weight: 800;
    line-height: 82px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #00EBB4;
}

.fmf-button {
    background-color: #415FE6;
    width: 385px;
    height: 49px;
    padding: 14px 20px 14px 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.fmf-button:hover {
    cursor: pointer;
    width: 390px;
    height: 52px;
}

.fmf-button a {
    color: #fff;
}

.fmf-button h2 {
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
}

.fmf-button a:hover {
    color: #fff;
}

@media screen and (max-width: 480px){

    .fmf-main {
        margin-top: 2rem;
        margin-bottom: 1rem;
        overflow: hidden;
    }
    
    .fmf-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: center;
    }

    .fmf-button {
        width: 330px;
        height: 49px;
        padding: 14px, 20px, 14px, 20px;
        border-radius: 5px;
        gap: 6px;
        margin: 5px;
    }

    .fmf-button:hover {
        cursor: pointer;
        width: 335px;
        height: 52px;
    }
}